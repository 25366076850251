.hero {
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  background-repeat: no-repeat;
  background-position: 50% 10%;
}

.hero h1 {
  font-weight: bold;
  color: white;
  font-size: 70px;
  line-height: 55px;
}

.hero-home, .hero-external, .hero-contact {
  background-size: 96% 100%;
}

.card-body {
  height: 130px;
}

@media (max-width: 1200px) {
  .card-body {
    height: 160px;
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 60px;
    line-height: 20px;
  }
}

@media (max-width: 576px) {
  .hero h1 {
    font-size: 30px;
    line-height: 0px;
  }
  .hero-about, .hero-problem {
    background-size: contain;
  }
}
