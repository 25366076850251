.hero-home {
  background-image: url('../../../assets/img/home.png');
}

.hero-home h1 span {
  font-size: 60px;
}

@media (max-width: 765px) {
  .hero-home h1 span {
    font-size: 50px;
  }
}

@media (max-width: 576px) {
  .hero-home h1 span {
    font-size: 25px;
  }
}
