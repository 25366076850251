.hero-contact {
  background-image: url('../../../assets/img/contact.png');
}

.form {
  width: 55%;
}

@media screen and (max-width: 992px) {
  .form {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .form {
    width: 90%;
  }
}

@media (max-width: 576px) {
  .form {
    width: 96%;
  }
}
