.hero-lecture {
  background-image: url('../../../assets/img/lecture.png');
}

.table {
  border-collapse: separate;
  width: 90%;
}

.table th {
  width: 40%;
  font-size: 25px;
  vertical-align: middle;
}

.table th:first-child,
.table td:first-child {
  border-right: 1px solid white;
}

.table tr:last-child td {
  border-bottom: 0;
}

@media (max-width: 1200px) {
  .hero-lecture {
    background-size: contain;
  }
}

@media (max-width: 768px) {
  .table th {
    font-size: 20px;
  }
  .table td h5 {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .table th {
    font-size: 14px;
  }
  .table td h5 {
    font-size: 12px;
  }
}
