.hero-problem {
  background-image: url('../../../assets/img/problem.png');
}

.problem-bank {
  width: 55%;
}

@media (max-width: 576px) {
  .problem-bank {
    width: 100%;
  }
}
