.header-profile {
  width: 32px;
}

.header-div {
  flex-grow: 0;
}

.header-name:hover,
.header-light:hover {
  color: yellow !important;
}

.header-light:hover {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  .header-name:hover,
  .header-light:hover {
    color: white;
  }
}
